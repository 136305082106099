.App {
  width: 100%;
  max-width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #e1e1e1;
}
.App:first-child {
  flex-grow: 1;
}
.App:last-child {
  flex-shrink: 0;
}

.generator {
  /* width: calc(100vw - 250px); */
  min-width: 100px;
  height: 100%;
  overflow: hidden;
  flex-grow: 1;
  text-align: center;
}

.generator canvas,
.generator shader-doodle {
  margin: 0;
}

#textCanvas {
  display: none;
}
